import React from "react";
import "./SearchBar.css";
import { useState } from "react";
import { Search, X } from "lucide-react";
import { useDispatch } from "react-redux";
import {
  setPageRedux,
  setSearchParamRedux,
} from "../../redux/slices/pricingSlice";

const SearchBar = ({ searchParam, setSearchParam }) => {
  const [search, setSearch] = useState(searchParam);
  const dispatch = useDispatch();

  return (
    <div className="max-w-[450px] border-1 border-solid border-[#E8E8E8] rounded-lg  overflow-hidden  font-normal text-xs w-[400px] outline-none flex box-border">
      <input
        placeholder="Search By Certificate or Stock Number"
        onChange={(ev) => {
          // console.log(ev.currentTarget.value, typeof ev.currentTarget.value);
          setSearch(ev.currentTarget.value ?? "");
        }}
        onKeyDown={(ev) => {
          if (ev.keyCode == 13) {
            dispatch(setSearchParamRedux(search));
            dispatch(setPageRedux(0));
            setSearchParam(search);
          }
        }}
        value={search}
        className="border-none px-2 h-8 font-normal text-xs w-[400px] outline-none rounded-lg"
      />
      {search.length > 0 && search == searchParam ? (
        <button
          className="border-none outline-none bg-white"
          onClick={() => {
            dispatch(setSearchParamRedux(""));
            setSearchParam("");
            dispatch(setPageRedux(0));
            setSearch("");
          }}
        >
          <X className="text-red-400" size={"18px"} />
        </button>
      ) : (
        ""
      )}
      <button
        className="border-none outline-none bg-white"
        onClick={() => {
          dispatch(setSearchParamRedux(search));
          dispatch(setPageRedux(0));
          setSearchParam(search);
        }}
      >
        <Search size={"18px"} />
      </button>
    </div>
  );
};

export default SearchBar;
